import React from 'react';
import Plus from '../../svg/Plus';

export default function QueuedProductCard({ product, onDelete }) {
    return (
        <div className='w-full flex flex-row justify-between items-center p-0'>
            <div className='flex flex-row gap-x-4'>
                {product.preview && (
                    <div className='w-[50px] h-[50px] rounded-[3px] overflow-hidden'>
                        <img src={product.preview} alt="preview" className='w-full h-full object-cover' />
                    </div>
                )}
                <div className='flex flex-col justify-center'>
                    <p className='text-white text-[12px]'>{product.title}</p>
                    <p className='text-[rgba(255,255,255,0.5)] text-[12px]'>{product.header}</p>
                </div>
            </div>
            <button onClick={onDelete}>
                <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer text-white' />
            </button>
        </div>
    );
} 