import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../layout/LayoutHeader';
import DizeeInput from '../../../../components/DizeeComponent/DizeeInput';
import ImageSelectionCard from '../../../../components/ImageSelectionCard';
import QueuedCustomCard from '../../../../components/AddSection/Custom/QueuedCustomCard';
import { setErrorAlert } from '../../../../store/alert/alertSlice';
import { uploadImage } from '../../../../utils/upload';

export default function AddContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { template, sectionTitle } = location.state || {};

  // Form state
  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [entries, setEntries] = useState(() => {
    const saved = localStorage.getItem('customQueueEntries');
    return saved ? JSON.parse(saved) : [];
  });

  // Handle image selection
  const handleImageChange = (file) => {
    setSelectedImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const resetImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
  };

  // Check if all required fields are filled
  const isFormComplete = template.fields.every(field => {
    if (field === 'image') return selectedImage !== null;
    return formData[field]?.trim();
  });

  // Check if form is completely empty
  const isFormEmpty = template.fields.every(field => {
    if (field === 'image') return selectedImage === null;
    return !formData[field];
  });

  // Button display logic
  const showQueue = isFormComplete;
  const showNext = entries.length > 0 || isFormComplete;
  const showClear = !isFormEmpty && !isFormComplete;

  // Clear form
  const handleClear = () => {
    setFormData({});
    resetImage();
  };

  // Add to queue
  const handleQueue = async () => {
    if (!isFormComplete) {
      dispatch(setErrorAlert('Please fill in all fields'));
      return;
    }

    let imageUrl = null;
    if (selectedImage) {
      imageUrl = await uploadImage(selectedImage);
      if (!imageUrl) {
        dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
        return;
      }
    }

    const newEntry = {
      ...formData,
      image: imageUrl,
      link: formData.link ? (formData.link.startsWith('http') ? formData.link : `https://${formData.link}`) : null
    };

    const updatedEntries = [...entries, newEntry];
    setEntries(updatedEntries);
    localStorage.setItem('customQueueEntries', JSON.stringify(updatedEntries));

    // Clear form
    handleClear();
  };

  // Remove from queue
  const handleDeleteFromQueue = (index) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
    localStorage.setItem('customQueueEntries', JSON.stringify(updatedEntries));
  };

  // Handle next/publish
  const handleNext = async () => {
    if (entries.length === 0 && !isFormComplete) {
      dispatch(setErrorAlert('Please add at least one entry or complete the current form'));
      return;
    }

    if (isFormComplete) {
      let imageUrl = null;
      if (selectedImage) {
        imageUrl = await uploadImage(selectedImage);
        if (!imageUrl) {
          dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
          return;
        }
      }

      const finalEntry = {
        ...formData,
        image: imageUrl,
        link: formData.link ? (formData.link.startsWith('http') ? formData.link : `https://${formData.link}`) : null
      };

      navigate('/add-section/custom/publish', {
        state: {
          entries: [...entries, finalEntry],
          template,
          sectionTitle
        }
      });
    } else {
      navigate('/add-section/custom/publish', {
        state: {
          entries,
          template,
          sectionTitle
        }
      });
    }
  };

  return (
    <LayoutHeader>
      <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
        <div className='px-4 my-[50px] flex w-full justify-between'>
          <p className='text-white'>Add Content</p>
          <div className='flex gap-[30px]'>
            {showNext && (
              <button onClick={handleNext} className='text-white cursor-pointer'>
                Next
              </button>
            )}
            {showQueue && (
              <button onClick={handleQueue} className='text-white cursor-pointer'>
                Queue
              </button>
            )}
            {showClear && (
              <button onClick={handleClear} className='text-white cursor-pointer'>
                Clear
              </button>
            )}
            <p onClick={() => navigate(-1)} className='text-white cursor-pointer'>
              Go back
            </p>
          </div>
        </div>

        <div className="w-full px-4 space-y-4">
          {template.fields.map(field => (
            <div key={field}>
              {field === 'image' ? (
                <ImageSelectionCard
                  txt="Add cover image"
                  dotimgclss={false}
                  onImageChange={handleImageChange}
                  imagePreview={imagePreview}
                  resetImage={resetImage}
                />
              ) : (
                <DizeeInput
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  placeholder={`Enter ${field}`}
                  value={formData[field] || ''}
                  onChange={(e) => setFormData(prev => ({ ...prev, [field]: e.target.value }))}
                  className="dizee-input w-full text-white"
                />
              )}
            </div>
          ))}
        </div>

        {entries.length > 0 && (
          <div className="flex flex-col justify-center items-center w-full mt-8">
            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer'>
              <div className='items-center flex flex-row text-white w-full'>
                <p className='text-[12px]'>In Queue</p>
              </div>
            </div>
            <div className='flex flex-col gap-y-[10px] w-full'>
              {entries.map((item, index) => (
                <QueuedCustomCard
                  key={index}
                  item={item}
                  template={template}
                  onDelete={() => handleDeleteFromQueue(index)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </LayoutHeader>
  );
}
