import React from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutHeader from '../../../../layout/LayoutHeader';
import SelectionCard from '../../../../components/SelectionCard';

export default function CustomSection() {
  const navigate = useNavigate();

  return (
    <LayoutHeader>
      <div className="w-[390px] flex flex-col justify-start items-center relative z-[0]">
        <div className='px-4 my-[50px] flex w-full justify-between'>
          <p className='text-white' style={{ fontSize: '12px' }}>Custom Section</p>
          <p onClick={() => navigate('/add-section')} 
             className='text-white cursor-pointer' 
             style={{ fontSize: '12px' }}>
            Go back
          </p>
        </div>
        
        <SelectionCard 
          txt="Create New" 
          link="/add-section/custom/create-new" 
          dotimgclss={false} 
        />
        
        <SelectionCard 
          txt="Existing Sections" 
          link="/add-section/custom/existing" 
          dotimgclss={false} 
        />
      </div>
    </LayoutHeader>
  );
} 