import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import { setFocusCustom } from "../../../../../store/focuspage/focuspageSlice";
import { uploadImage } from "../../../../../utils/upload";
import { setLoader, setErrorAlert } from "../../../../../store/alert/alertSlice";

export default function Step5() {
    const [preview, setPreview] = useState(null);
    const [file, setFile] = useState(null);
    const [headerText, setHeaderText] = useState("");
    const [subheaderText, setSubheaderText] = useState("");
    let custom = useSelector(state => state.focuspage.custom);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (custom?.avatarPreview) {
            setPreview(custom?.avatarPreview);
        }
        if (custom?.headerText) {
            setHeaderText(custom.headerText);
        }
        if (custom?.subheaderText) {
            setSubheaderText(custom.subheaderText);
        }
    }, [custom?.avatar]);

    const changeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreview(URL.createObjectURL(selectedFile));
            dispatch(setFocusCustom({ 
                avatar: selectedFile,
                avatarPreview: URL.createObjectURL(selectedFile)
            }));
        }
    };

    const handleHeaderTextChange = (e) => {
        const newHeaderText = e.target.value;
        setHeaderText(newHeaderText);
        
        dispatch(setFocusCustom({ 
            ...custom,
            headerText: newHeaderText,
            content: [
                {
                    type: 'header',
                    avatar: custom?.avatar || preview,
                    title: newHeaderText,
                    subtitle: subheaderText
                },
                ...(custom?.content || []).filter(item => item.type !== 'header')
            ]
        }));
    };

    const handleSubheaderTextChange = (e) => {
        const newSubheaderText = e.target.value;
        setSubheaderText(newSubheaderText);
        
        dispatch(setFocusCustom({ 
            ...custom,
            subheaderText: newSubheaderText,
            content: [
                {
                    type: 'header',
                    avatar: custom?.avatar || preview,
                    title: headerText,
                    subtitle: newSubheaderText
                },
                ...(custom?.content || []).filter(item => item.type !== 'header')
            ]
        }));
    };

    const handleNext = async () => {
        if (file) {
            dispatch(setLoader(true));
            try {
                const url = await uploadImage(file);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
                
                // Create the header content object
                const headerContentItem = {
                    type: 'header',
                    avatar: url,
                    title: headerText,
                    subtitle: subheaderText
                };
                
                // Update custom with all header-related data
                dispatch(setFocusCustom({ 
                    avatar: url,
                    headeractive: true,
                    headerText: headerText,
                    subheaderText: subheaderText,
                    content: [
                        headerContentItem,
                        ...(custom?.content || []).filter(item => item.type !== 'header')
                    ]
                }));
                
                navigate("/focus-page/custom-type/step6");
            } catch (error) {
                console.error('Error uploading image:', error);
                dispatch(setErrorAlert('Error uploading image'));
            } finally {
                dispatch(setLoader(false));
            }
        } else {
            navigate("/focus-page/custom-type/step6");
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Add header image</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${file || custom?.avatar ? "opacity-100" : "opacity-50"}`}
                                onClick={handleNext}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/custom-type/step4")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4">
                        <div
                            className="text-white cursor-pointer"
                            onClick={() => document.getElementById("avatar").click()}
                        >
                            {preview ? "Change header image" : "Upload header image"}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            id="avatar"
                            onChange={changeAvatar}
                        />
                        {preview ? (
                            <div className="profile-picture my-8 min-h-[100px]">
                                <div className="avatar">
                                    <img
                                        src={preview}
                                        className="h-[80px] w-[80px] rounded-full"
                                        alt="header"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="mb-8" />
                        )}

                        <div className="mt-6">
                            <DizeeInput
                                placeholder="Add header text (optional)"
                                onChange={handleHeaderTextChange}
                                value={headerText}
                                className="w-full"
                            />
                        </div>

                        <div className="mt-6">
                            <DizeeInput
                                placeholder="Add subheader text (optional)"
                                onChange={handleSubheaderTextChange}
                                value={subheaderText}
                                className="w-full"
                            />
                        </div>
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Upload an image for your focus page header.</p>
                            <p>You can also add optional text and subtext to display in the header area.</p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}
