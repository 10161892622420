import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import { setFocusMusic } from "../../../../../store/focuspage/focuspageSlice";
import { useEffect, useState } from "react";

const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs((h / 60) % 2 - 1));
    let m = l - c / 2;
    let r = 0, g = 0, b = 0;
    if (h >= 0 && h < 60) {
        r = c; g = x; b = 0;
    } else if (h >= 60 && h < 120) {
        r = x; g = c; b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0; g = c; b = x;
    } else if (h >= 180 && h < 240) {
        r = 0; g = x; b = c;
    } else if (h >= 240 && h < 300) {
        r = x; g = 0; b = c;
    } else if (h >= 300 && h < 360) {
        r = c; g = 0; b = x;
    }
    r = Math.round((r + m) * 255).toString(16).padStart(2, '0');
    g = Math.round((g + m) * 255).toString(16).padStart(2, '0');
    b = Math.round((b + m) * 255).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`;
};


export default function Step3() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let music = useSelector(state => state.focuspage.music)
    const [isValid, setIsValid] = useState(false);
    const [brightness, setBrightness] = useState(50)
    const [hue, setHue] = useState(0);
    const [color, setColor] = useState("FFFFFF")

    useEffect(() => {
        if (music?.background) {
            setIsValid(true)
        }
    }, [])

    const handleHueChangeBackground = (e) => {
        setHue(e.target.value);
        const hexColor = hslToHex(e.target.value, 100, brightness);
        const colorValue = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
        setColor(colorValue);
        dispatch(setFocusMusic({ background: colorValue }));
        setIsValid(true);
    };

    const handleBrightnessChangeBackground = (e) => {
        setBrightness(e.target.value);
        const hexColor = hslToHex(hue, 100, e.target.value);
        const colorValue = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
        setColor(colorValue);
        dispatch(setFocusMusic({ background: colorValue }));
        setIsValid(true);
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Select main color</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${isValid ? "opacity-100" : "opacity-50"}`}
                                onClick={() => isValid && navigate("/focus-page/music-type/step4")}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/music-type/step2")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4">
                        <div className="flex flex-col justify-center">
                            <input
                                type="range"
                                min="0"
                                max="360"
                                value={hue}
                                onChange={handleHueChangeBackground}
                                className="hue-slider"
                                style={{ width: "270px" }}
                            />
                        </div>

                        <div className="flex flex-col justify-center mt-6">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={brightness}
                                onChange={handleBrightnessChangeBackground}
                                className="brightness-slider"
                                style={{
                                    background: `linear-gradient(to left, white, hsl(${hue}, 100%, 50%))`,
                                    margin: 0,
                                    width: "270px"
                                }}
                            />
                        </div>

                        <div className="flex mt-3">
                            <span className="text-white mr-2">HEX</span>
                            <span className="text-white w-[52px]">#{color}</span>
                            <span className="w-[16px] h-[16px] ml-4" style={{ backgroundColor: `#${color}` }}></span>
                        </div>
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5]">
                            <p>Choose a color that will define the look of your focus page.</p>
                            <p>This will be the main background color throughout.</p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
};

