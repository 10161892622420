import React from 'react';
import { useSelector } from "react-redux";
import LayoutHeader from "../../../../layout/LayoutHeader";
import { getContrastColor, maxLength } from '../../../../components/DizeeComponent/DixeeInput2';
import CusCardMusic from '../../../../components/CusCardMusic';

export default function CustomFocusPage() {
    const custom = useSelector(state => state.focuspage.custom);
    const userData = useSelector(state => state.user.userArticle);

    // Debug logs
    console.log("Raw custom data:", {
        headerText: custom?.headerText,
        subheaderText: custom?.subheaderText,
        content: custom?.content
    });

    // Debug logs for header-related data
    console.log("Custom data:", {
        avatar: custom?.avatar,
        avatarPreview: custom?.avatarPreview,
        headerText: custom?.headerText,
        subheaderText: custom?.subheaderText,
        content: custom?.content,
        background: custom?.background,
        headeractive: custom?.headeractive
    });

    // If there's content, log the header content
    if (custom?.content?.length > 0) {
        console.log("Header content:", custom.content.find(item => item.type === 'header'));
    }

    // Add this log right before rendering the image
    console.log("Image URL being used:", custom?.avatarPreview || custom?.avatar);

    // Add this before the return statement
    console.log("Full custom data structure:", {
        content: custom?.content,
        headerData: custom?.content?.find(item => item.type === 'header'),
        directHeaderImage: custom?.avatar,
        uploadedUrl: custom?.content?.find(item => item.type === 'header')?.avatar
    });

    // Add this before the return statement
    console.log("Step by step debug:", {
        hasContent: Boolean(custom?.content),
        contentLength: custom?.content?.length,
        firstContentItem: custom?.content?.[0],
        headerActive: custom?.headeractive,
        rawAvatar: custom?.avatar,
        rawPreview: custom?.avatarPreview
    });

    // Get header content from the content array
    const headerContent = custom?.content?.find(item => item.type === 'header');
    console.log("Header content:", headerContent);
    
    // Use header content if available, otherwise fall back to root level data
    const headerImage = headerContent?.avatar || custom?.avatar;
    const headerTitle = headerContent?.title || custom?.headerText;
    const headerSubtitle = headerContent?.subtitle || custom?.subheaderText;

    console.log("Final header values:", {
        headerImage,
        headerTitle,
        headerSubtitle
    });

    return (
        <LayoutHeader>
            <div className="w-[350px] sm:max-w-[390px] pb-[16px] h-full flex flex-col justify-start items-center relative rounded-[20px]"
                style={{ 
                    backgroundColor: `#${custom?.background || '1A1A25'}`,
                    color: `#${getContrastColor(custom?.background)}`
                }}
            >
                <div className="flex py-4 z-1 ml-4 w-full items-center text-white">
                    <div className="mx-auto">
                        diz.ee/{userData?.domain}/{custom?.page}
                    </div>
                </div>

                <div className="max-w-[350px] sm:max-w-[390px] h-[490px] flex relative">
                    <img 
                        src={headerImage}
                        alt="header" 
                        className="w-full h-full object-cover rounded-[20px]" 
                    />
                    <div 
                        className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t to-transparent flex justify-between items-end p-4"
                        style={{
                            backgroundImage: `linear-gradient(to top, ${custom?.background ? `#${custom.background}` : 'black'}, transparent)`,
                        }}
                    >
                        <div className="w-full">
                            <p className="text-[16px] text-white">
                                {headerSubtitle || "No subtitle"}
                            </p>
                            <p className="text-[20px] text-white font-bold">
                                {headerTitle || "No title"}
                            </p>
                        </div>
                    </div>
                </div>

                {custom?.content?.filter(item => item.type === 'music').length > 0 && (
                    <div className="w-full mb-[60px] overflow-x-auto px-4 flex flex-row gap-x-4 mt-4">
                        {custom?.content?.filter(item => item.type === 'music').map((item, index) => (
                            <CusCardMusic
                                key={index}
                                imgurl={item?.avatar}
                                txt={item?.title}
                                artist={item?.subtitle}
                                userArticle={custom}
                                links={item?.links}
                                item={item}
                            />
                        ))}
                    </div>
                )}
            </div>
        </LayoutHeader>
    );
} 