import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../layout/LayoutHeader';
import AddedCustomCard from '../../../../components/AddSection/Custom/AddedCustomCard';

export default function ExistingSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customSections = useSelector((state) => state.addsection.customSections);

  useEffect(() => {
    // TODO: Add fetch custom sections thunk when backend is ready
  }, [dispatch]);

  return (
    <LayoutHeader>
      <div className="w-[390px] flex flex-col items-center relative z-[0]">
        <div className='px-4 my-[50px] flex w-full justify-between'>
          <p className='text-white'>Existing Sections</p>
          <div className='flex gap-[30px]'>
            <p onClick={() => navigate('/add-section/custom')} 
               className='text-white cursor-pointer'>
              Go back
            </p>
          </div>
        </div>

        {customSections?.length > 0 ? (
          <div className="flex flex-col justify-center items-center w-full">
            {customSections.filter(section => {
              if (!section) return false;
              if (section.publish && typeof section.publish === 'object' && !section.publish.value) return false;
              return true;
            }).map((section, index) => (
              <AddedCustomCard
                key={index}
                section={section}
              />
            ))}
          </div>
        ) : (
          <div className="text-white mt-8 px-4 w-full">
            <p>Create a new custom section to get started.</p>
          </div>
        )}
      </div>
    </LayoutHeader>
  );
}
