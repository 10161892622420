import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom } from '../../../../../store/focuspage/focuspageSlice';
import Check from '../../../../../svg/Check';
import VideoCard from '../../../../../components/Cards/VideoCard';
import QueuedVideoCard from '../../../../../components/Cards/QueuedVideoCard';
import AddedVideoCard from '../../../../../components/Cards/AddedVideoCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';

export default function AddVideoContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isValidUrl = (url) => {
        if (!url) return false;
        // Check for any TLD (one or more letters after the last dot)
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const [link, setLink] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [entries, setEntries] = useState([]);
    const [addedVideos, setAddedVideos] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);
    const [title, setTitle] = useState('');
    
    const isFormComplete = title && isValidUrl(link) && preview;

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        // Reset the file input value after processing
        e.target.value = '';
    };

    const handleAddToQueue = () => {
        if (isFormComplete) {
            const newEntry = {
                title,
                link,
                image: file,
                preview
            };
            setEntries([...entries, newEntry]);
            // Clear form
            setTitle('');
            setLink('');
            setFile(null);
            setPreview(null);
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
    };

    const handleSubmit = () => {
        const contentToSubmit = [];
        
        if (isFormComplete) {
            contentToSubmit.push({
                type: 'video', // Add content type
                title,
                link,
                preview
            });
        }

        if (entries.length > 0) {
            // Add type to each entry
            const entriesWithType = entries.map(entry => ({
                ...entry,
                type: 'video'
            }));
            contentToSubmit.push(...entriesWithType);
        }

        if (contentToSubmit.length > 0) {
            dispatch(setFocusCustom(contentToSubmit));
            setEntries([]);
            setTitle('');
            setLink('');
            setFile(null);
            setPreview(null);
            navigate(-1); // Navigate back after submitting
        }
    };

    const handleVideoSubmissionSuccess = () => {
        setIsOverlayVisible(false);
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-screen flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-auto">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20 after:content-[''] after:absolute after:left-0 after:right-0 after:top-[-50px] after:h-[50px] after:bg-black">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddToQueue}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${(entries.length > 0 || isFormComplete) ? "opacity-100" : "opacity-50"}`}
                                    onClick={() => (entries.length > 0 || isFormComplete) && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div 
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 mt-[120px] relative z-10 mb-[100px]">
                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                                {title !== '' && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter link"
                                    onChange={handleLinkChange}
                                    value={link}
                                />
                                {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="w-full">
                                <p 
                                    className="text-white text-[12px] mb-2 cursor-pointer"
                                    onClick={() => document.getElementById('file-input').click()}
                                >
                                    {preview ? 'Change thumbnail' : 'Upload thumbnail'}
                                </p>
                                {preview && (
                                    <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                        <img src={preview} alt="preview" className="w-full h-full object-cover" />
                                    </div>
                                )}
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </div>

                            {entries.length > 0 && (
                                <div className="w-full mt-8">
                                    <p className="text-white text-[12px] mb-4">In Queue</p>
                                    <div className="flex flex-col gap-y-4 w-full">
                                        {entries.map((entry, index) => (
                                            <QueuedVideoCard
                                                key={index}
                                                video={entry}
                                                onDelete={() => handleDeleteFromQueue(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {addedVideos.length > 0 && (
                                <div className="flex flex-col justify-center items-center w-full mt-8">
                                    <div className="p-4 pb-[40px] flex w-full justify-between items-center">
                                        <div className="items-center flex flex-row text-white w-full">
                                            <p>Added Videos</p>
                                        </div>
                                    </div>
                                    {addedVideos.map((video, index) => (
                                        <AddedVideoCard
                                            key={index}
                                            video={video}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={setItemForBackend}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="w-full fixed bottom-0 left-0 bg-black py-4 z-10">
                            <div className="w-[390px] mx-auto px-4">
                                <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5]">
                                    <p>Add your video content to display in your focus page.</p>
                                    <p>Video link is required. Add a thumbnail to enhance the presentation.</p>
                                </div>
                            </div>
                        </div>

                        {isOverlayVisible && (
                            <CustomOverlay 
                                setOverlayVisible={setIsOverlayVisible}
                                itemForBackend={itemForBackend}
                                setItemForBackend={setItemForBackend}
                            />
                        )}
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
} 