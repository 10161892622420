import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom } from '../../../../../store/focuspage/focuspageSlice';
import Check from '../../../../../svg/Check';
import QueuedTextCard from '../../../../../components/Cards/QueuedTextCard';
import AddedTextCard from '../../../../../components/Cards/AddedTextCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';

export default function AddTextContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const style = state?.style || 'text';

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const [title, setTitle] = useState('');
    const [mainText, setMainText] = useState('');
    const [optionalText, setOptionalText] = useState('');
    const [entries, setEntries] = useState([]);
    const [addedTexts, setAddedTexts] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);
    const [link, setLink] = useState('');
    
    const isFormComplete = title && mainText && isValidUrl(link);

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const handleAddToQueue = () => {
        if (isFormComplete) {
            setEntries([...entries, { title, mainText, optionalText, link }]);
            setTitle('');
            setMainText('');
            setOptionalText('');
            setLink('');
        }
    };

    const handleDeleteFromQueue = (index) => {
        setEntries(entries.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        const contentToSubmit = [];
        
        if (isFormComplete) {
            contentToSubmit.push({
                title,
                mainText,
                optionalText,
                link
            });
        }

        if (entries.length > 0) {
            contentToSubmit.push(...entries);
        }

        if (contentToSubmit.length > 0) {
            setAddedTexts(prev => [...prev, ...contentToSubmit]);
            setEntries([]);
            setTitle('');
            setMainText('');
            setOptionalText('');
            setLink('');
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-screen flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-auto">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20 after:content-[''] after:absolute after:left-0 after:right-0 after:top-[-50px] after:h-[50px] after:bg-black">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddToQueue}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${(entries.length > 0 || isFormComplete) ? "opacity-100" : "opacity-50"}`}
                                    onClick={() => (entries.length > 0 || isFormComplete) && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div 
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="mt-[120px] px-4 flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    maxLength={30}
                                />
                                {title && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter main text"
                                    onChange={(e) => setMainText(e.target.value)}
                                    value={mainText}
                                    multiline
                                    maxLength={200}
                                />
                                {mainText && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter optional text"
                                    onChange={(e) => setOptionalText(e.target.value)}
                                    value={optionalText}
                                    multiline
                                    maxLength={100}
                                />
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter link"
                                    onChange={handleLinkChange}
                                    value={link}
                                />
                                {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {/* Queue Section */}
                            {entries.length > 0 && (
                                <div className="w-full mt-8">
                                    <p className="text-white text-[12px] mb-4">In Queue</p>
                                    <div className="flex flex-col gap-y-4 w-full">
                                        {entries.map((entry, index) => (
                                            <QueuedTextCard
                                                key={index}
                                                text={entry}
                                                onDelete={() => handleDeleteFromQueue(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Added Texts Section */}
                            {addedTexts.length > 0 && (
                                <div className="flex flex-col justify-center items-center w-full mt-8">
                                    <div className="p-4 pb-[40px] flex w-full justify-between items-center">
                                        <div className="items-center flex flex-row text-white w-full">
                                            <p>Added Texts</p>
                                        </div>
                                    </div>
                                    {addedTexts.map((text, index) => (
                                        <AddedTextCard
                                            key={index}
                                            text={text}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={setItemForBackend}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {isOverlayVisible && (
                <CustomOverlay 
                    setOverlayVisible={setIsOverlayVisible}
                    itemForBackend={itemForBackend}
                    setItemForBackend={setItemForBackend}
                    contentType="text"
                />
            )}
        </LayoutHeader>
    );
} 