import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom } from '../../../../../store/focuspage/focuspageSlice';
import Check from '../../../../../svg/Check';
import QueuedFormCard from '../../../../../components/Cards/QueuedFormCard';
import AddedFormCard from '../../../../../components/Cards/AddedFormCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';

export default function AddFormContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const style = state?.style || 'form';

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [entries, setEntries] = useState([]);
    const [addedForms, setAddedForms] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);
    
    const isFormComplete = title && text && buttonText;

    const handleAddToQueue = () => {
        if (isFormComplete) {
            setEntries([...entries, { title, text, buttonText }]);
            setTitle('');
            setText('');
            setButtonText('');
        }
    };

    const handleDeleteFromQueue = (index) => {
        setEntries(entries.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        const contentToSubmit = [];
        
        if (isFormComplete) {
            contentToSubmit.push({
                title,
                text,
                buttonText
            });
        }

        if (entries.length > 0) {
            contentToSubmit.push(...entries);
        }

        if (contentToSubmit.length > 0) {
            setAddedForms(prev => [...prev, ...contentToSubmit]);
            setEntries([]);
            setTitle('');
            setText('');
            setButtonText('');
        }
    };

    // Reference layout structure from AddProductContent.jsx
    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-screen flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-auto">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20 after:content-[''] after:absolute after:left-0 after:right-0 after:top-[-50px] after:h-[50px] after:bg-black">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddToQueue}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${(entries.length > 0 || isFormComplete) ? "opacity-100" : "opacity-50"}`}
                                    onClick={() => (entries.length > 0 || isFormComplete) && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div 
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="mt-[120px] px-4 flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    maxLength={30}
                                />
                                {title && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter text"
                                    onChange={(e) => setText(e.target.value)}
                                    value={text}
                                    multiline
                                    maxLength={200}
                                />
                                {text && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter button text"
                                    onChange={(e) => setButtonText(e.target.value)}
                                    value={buttonText}
                                    maxLength={20}
                                />
                                {buttonText && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {/* Queue section */}
                            startLine: 153
                            endLine: 166

                            {/* Added forms section */}
                            startLine: 169
                            endLine: 185
                        </div>
                    </div>
                </div>
            </div>

            {isOverlayVisible && (
                <CustomOverlay 
                    setOverlayVisible={setIsOverlayVisible}
                    itemForBackend={itemForBackend}
                    setItemForBackend={setItemForBackend}
                    contentType="form"
                />
            )}
        </LayoutHeader>
    );
} 