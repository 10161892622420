import axios from 'axios'
import { BASE_URL } from './axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


export const postDataAPI = async (url, post, token) => {
    const isFormData = post instanceof FormData;
    
    const config = {
        headers: {
            'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
            'Authorization': token
        }
    }
    
    try {
        console.log('Making request to:', `${BASE_URL}/${url}`);
        console.log('Request config:', {
            headers: config.headers,
            data: post
        });
        
        const res = await axios.post(`${BASE_URL}/${url}`, post, config);
        return res;
    } catch (err) {
        console.error('Request failed:', {
            url: `${BASE_URL}/${url}`,
            status: err.response?.status,
            statusText: err.response?.statusText,
            data: err.response?.data
        });
        throw err;
    }
};

export const getDataAPI = async (url, token) => {
    const res = await axios.get(`${BASE_URL}/${url}`,
        {
            headers: { Authorization: token }
        })

    return res;
}

export const putDataAPI = async (url, post, token) => {

    const res = await axios.put(`${BASE_URL}/${url}`, post,
        {
            headers: { Authorization: token }
        })

    return res;
}