import React from 'react';
import { maxLength, removeUploadsFromUrl } from '../../DizeeComponent/DixeeInput2';
import Plus from '../../../svg/Plus';

export default function QueuedCustomCard({ item, template, onDelete }) {
  return (
    <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center'>
      <div className='flex flex-row justify-start items-center gap-x-[11px]'>
        {item.image && (
          <img 
            src={removeUploadsFromUrl(item.image)} 
            alt='preview' 
            className='object-cover h-[50px] w-[50px] rounded-[3px]'
          />
        )}
        <div className='flex flex-col items-start justify-start h-full p-2'>
          <p className='text-white mb-[6px]' style={{ fontSize: '12px' }}>
            {maxLength(item.title, 30)}
          </p>
          {item.description && (
            <p className='text-gray-400' style={{ fontSize: '10px' }}>
              {item.description}
            </p>
          )}
        </div>
      </div>
      <button onClick={onDelete}>
        <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer text-white' />
      </button>
    </div>
  );
} 