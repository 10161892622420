import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CrossArrow from '../../../svg/CrossArrow';
import { setFocusCustom } from '../../../store/focuspage/focuspageSlice';

export default function CustomRedirectCard({ txt, link, item }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setFocusCustom(item));
        navigate(link);
    };

    return (
        <div className='p-4 py-6 flex w-full justify-between items-center'>
            <div className='flex items-center'>
                <span className='text-white'>{txt}</span>
            </div>
            <button onClick={handleClick}>
                <CrossArrow className='h-[14px] w-[14px] cursor-pointer' />
            </button>
        </div>
    );
} 