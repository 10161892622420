import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom, clearFocusSection } from '../../../../../store/focuspage/focuspageSlice';
import { addCustomForFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { uploadImage } from '../../../../../utils/upload';
import Check from '../../../../../svg/Check';
import QueuedMusicCard from '../../../../../components/Cards/QueuedMusicCard';
import AddedMusicCard from '../../../../../components/Cards/AddedMusicCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';
import { checkPageAvailableForMusicThunk } from '../../../../../store/focuspage/focuspageThunk';

export default function AddMusicContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('dizeeToken');
    const custom = useSelector(state => state.focuspage.custom);

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [link, setLink] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [entries, setEntries] = useState([]);
    const [addedMusic, setAddedMusic] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);
    
    const isFormComplete = title && isValidUrl(link) && preview;

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    const handleAddToQueue = () => {
        if (isFormComplete) {
            const newEntry = {
                title,
                subtitle,
                link,
                image: file,
                preview
            };
            setEntries([...entries, newEntry]);
            // Clear form
            setTitle('');
            setSubtitle('');
            setLink('');
            setFile(null);
            setPreview(null);
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
    };

    const handleSubmit = async () => {
        if (!custom?.page) {
            dispatch(setErrorAlert("Please set a page name first"));
            return;
        }

        let contentToSubmit = [...entries];
        
        if (isFormComplete) {
            contentToSubmit.push({
                title,
                subtitle,
                link,
                image: file,
                preview
            });
        }

        if (contentToSubmit.length === 0) {
            dispatch(setErrorAlert("Please add data to the section"));
            return;
        }

        dispatch(setLoader(true));

        try {
            const processedMusic = await Promise.all(
                contentToSubmit.map(async (entry) => {
                    let url = '';
                    if (entry.image) {
                        url = await uploadImage(entry.image);
                        if (!url) {
                            throw new Error('Image cannot contain nudity, violence or drugs');
                        }
                    }

                    return {
                        type: 'music',
                        title: entry.title,
                        subtitle: entry.subtitle,
                        link: entry.link,
                        avatar: url
                    };
                })
            );

            // Get existing header content if available
            const existingHeader = custom?.content?.find(item => item.type === 'header');
            
            const dataCopy = {
                ...custom, // Preserve all existing custom data
                page: custom.page,
                type: 'custom',
                headeractive: true,
                content: [
                    // Preserve existing header or create from root level data
                    existingHeader || {
                        type: 'header',
                        avatar: custom?.avatar,
                        title: custom?.headerText,
                        subtitle: custom?.subheaderText
                    },
                    ...processedMusic
                ]
            };

            const result = await dispatch(addCustomForFocusThunk({ token, payload: dataCopy })).unwrap();
            
            if (result.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            } else {
                throw new Error(result.message || 'Failed to create focus page');
            }
        } catch (error) {
            console.error('Error creating custom focus page:', error);
            dispatch(setErrorAlert(error?.message || 'Error creating focus page'));
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-screen flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-auto">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20 after:content-[''] after:absolute after:left-0 after:right-0 after:top-[-50px] after:h-[50px] after:bg-black">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddToQueue}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${(entries.length > 0 || isFormComplete) ? "opacity-100" : "opacity-50"}`}
                                    onClick={() => (entries.length > 0 || isFormComplete) && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div 
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 mt-[120px] relative z-10 mb-[100px]">
                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                                {title !== '' && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter subtitle (optional)"
                                    onChange={(e) => setSubtitle(e.target.value)}
                                    value={subtitle}
                                />
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter link"
                                    onChange={handleLinkChange}
                                    value={link}
                                />
                                {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="w-full">
                                <p 
                                    className="text-white text-[12px] mb-2 cursor-pointer"
                                    onClick={() => document.getElementById('file-input').click()}
                                >
                                    {preview ? 'Change image' : 'Upload image'}
                                </p>
                                {preview && (
                                    <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                        <img src={preview} alt="preview" className="w-full h-full object-cover" />
                                    </div>
                                )}
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </div>

                            {entries.length > 0 && (
                                <div className="w-full mt-8">
                                    <p className="text-white text-[12px] mb-4">In Queue</p>
                                    <div className="flex flex-col gap-y-4 w-full">
                                        {entries.map((entry, index) => (
                                            <QueuedMusicCard
                                                key={index}
                                                music={entry}
                                                onDelete={() => handleDeleteFromQueue(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {addedMusic.length > 0 && (
                                <div className="flex flex-col justify-center items-center w-full mt-8">
                                    <div className="p-4 pb-[40px] flex w-full justify-between items-center">
                                        <div className="items-center flex flex-row text-white w-full">
                                            <p>Added Music</p>
                                        </div>
                                    </div>
                                    {addedMusic.map((music, index) => (
                                        <AddedMusicCard
                                            key={index}
                                            music={music}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={setItemForBackend}
                                        />
                                    ))}
                                </div>
                            )}

                            {isOverlayVisible && (
                                <CustomOverlay 
                                    setOverlayVisible={setIsOverlayVisible}
                                    itemForBackend={itemForBackend}
                                    setItemForBackend={setItemForBackend}
                                />
                            )}
                        </div>

                        <div className="w-full fixed bottom-0 left-0 bg-black py-4 z-10">
                            <div className="w-[390px] mx-auto px-4">
                                <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5]">
                                    <p>Add your music content to display in your focus page.</p>
                                    <p>Title and link are required. Add an image and subtitle to enhance the presentation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
} 