import React, { useState } from 'react';
import { DizeeInput2 } from '../../../DizeeComponent/DixeeInput2';
import DizeeButton from '../../../DizeeComponent/DizeeButton';

export default function CustomOverlay({ setOverlayVisible, itemForBackend, setItemForBackend, contentType = 'event' }) {
    const [editForm, setEditForm] = useState({
        // Common fields
        title: itemForBackend?.title || '',
        link: itemForBackend?.link || '',
        preview: itemForBackend?.preview || null,
        
        // Event specific fields
        lineOne: itemForBackend?.lineOne || '',
        lineTwo: itemForBackend?.lineTwo || '',
        lineThree: itemForBackend?.lineThree || '',
        
        // Product specific fields
        header: itemForBackend?.header || '',
        description: itemForBackend?.description || '',
        
        // Music/Video specific fields
        subtitle: itemForBackend?.subtitle || '',
        artist: itemForBackend?.artist || '',
        
        mainText: itemForBackend?.mainText || '',
        optionalText: itemForBackend?.optionalText || '',
    });

    const onSaveEdit = () => {
        setItemForBackend({
            ...itemForBackend,
            ...editForm
        });
        setOverlayVisible(false);
    };

    const renderFields = () => {
        switch(contentType) {
            case 'product':
                return (
                    <>
                        <DizeeInput2
                            label="Title"
                            placeholder="Enter title"
                            className="dizee-input w-full text-white"
                            value={editForm.title}
                            onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Header"
                            placeholder="Enter header"
                            className="dizee-input w-full text-white"
                            value={editForm.header}
                            onChange={(e) => setEditForm(prev => ({ ...prev, header: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Description"
                            placeholder="Enter description"
                            className="dizee-input w-full text-white"
                            value={editForm.description}
                            onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Link"
                            placeholder="Enter link"
                            className="dizee-input w-full text-white"
                            value={editForm.link}
                            onChange={(e) => setEditForm(prev => ({ ...prev, link: e.target.value }))}
                        />
                    </>
                );
            case 'event':
                return (
                    <>
                        <DizeeInput2
                            label="Title"
                            placeholder="Enter title"
                            className="dizee-input w-full text-white"
                            value={editForm.title}
                            onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                        />
                        <DizeeInput2
                            label="First line"
                            placeholder="Enter first line"
                            className="dizee-input w-full text-white"
                            value={editForm.lineOne}
                            onChange={(e) => setEditForm(prev => ({ ...prev, lineOne: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Second line"
                            placeholder="Enter second line"
                            className="dizee-input w-full text-white"
                            value={editForm.lineTwo}
                            onChange={(e) => setEditForm(prev => ({ ...prev, lineTwo: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Third line"
                            placeholder="Enter third line"
                            className="dizee-input w-full text-white"
                            value={editForm.lineThree}
                            onChange={(e) => setEditForm(prev => ({ ...prev, lineThree: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Link"
                            placeholder="Enter link"
                            className="dizee-input w-full text-white"
                            value={editForm.link}
                            onChange={(e) => setEditForm(prev => ({ ...prev, link: e.target.value }))}
                        />
                    </>
                );
            case 'social':
                return (
                    <>
                        <DizeeInput2
                            label="Title"
                            placeholder="Enter title"
                            className="dizee-input w-full text-white"
                            value={editForm.title}
                            onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                        />
                        <DizeeInput2
                            label="Link"
                            placeholder="Enter link"
                            className="dizee-input w-full text-white"
                            value={editForm.link}
                            onChange={(e) => setEditForm(prev => ({ ...prev, link: e.target.value }))}
                        />
                    </>
                );
            case 'text':
                return (
                    <>
                        <DizeeInput2
                            label="Title"
                            placeholder="Enter title"
                            className="dizee-input w-full text-white"
                            value={editForm.title}
                            onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                            maxLength={30}
                        />
                        <DizeeInput2
                            label="Main Text"
                            placeholder="Enter main text"
                            className="dizee-input w-full text-white"
                            value={editForm.mainText}
                            onChange={(e) => setEditForm(prev => ({ ...prev, mainText: e.target.value }))}
                            multiline
                            maxLength={200}
                        />
                        <DizeeInput2
                            label="Optional Text"
                            placeholder="Enter optional text"
                            className="dizee-input w-full text-white"
                            value={editForm.optionalText}
                            onChange={(e) => setEditForm(prev => ({ ...prev, optionalText: e.target.value }))}
                            multiline
                            maxLength={100}
                        />
                        <DizeeInput2
                            label="Link"
                            placeholder="Enter link"
                            className="dizee-input w-full text-white"
                            value={editForm.link}
                            onChange={(e) => setEditForm(prev => ({ ...prev, link: e.target.value }))}
                        />
                    </>
                );
            case 'form':
                return (
                    <>
                        <DizeeInput2
                            label="Title"
                            placeholder="Enter title"
                            className="dizee-input w-full text-white"
                            value={editForm.title}
                            onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                            maxLength={30}
                        />
                        <DizeeInput2
                            label="Text"
                            placeholder="Enter text"
                            className="dizee-input w-full text-white"
                            value={editForm.text}
                            onChange={(e) => setEditForm(prev => ({ ...prev, text: e.target.value }))}
                            multiline
                            maxLength={200}
                        />
                        <DizeeInput2
                            label="Button Text"
                            placeholder="Enter button text"
                            className="dizee-input w-full text-white"
                            value={editForm.buttonText}
                            onChange={(e) => setEditForm(prev => ({ ...prev, buttonText: e.target.value }))}
                            maxLength={20}
                        />
                    </>
                );
            // Add cases for music and video if needed
            default:
                return null;
        }
    };

    return (
        <div className='fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50'>
            <div className='w-[390px] flex flex-col justify-start items-center relative z-[0] bg-black'>
                <div className='px-4 flex w-full justify-between items-center mt-[50px]'>
                    <p className='text-white text-[12px]'>Edit details</p>
                    <div className='flex gap-x-4'>
                        <DizeeButton label={'Confirm'} onClick={onSaveEdit} />
                        <DizeeButton label={'Go back'} onClick={() => setOverlayVisible(false)} />
                    </div>
                </div>
                <div className='px-4 mt-[80px] flex flex-col w-full gap-y-6'>
                    {renderFields()}
                    
                    {editForm.preview && (
                        <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                            <img src={editForm.preview} alt="preview" className="w-full h-full object-cover" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
} 