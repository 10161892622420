import { useEffect, useState } from "react";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import { useDispatch, useSelector } from "react-redux";
import { setFocusProduct } from "../../../../../store/focuspage/focuspageSlice";
import { useNavigate } from "react-router";
import { uploadImage } from "../../../../../utils/upload";
import { setLoader, setErrorAlert } from "../../../../../store/alert/alertSlice";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import Check from '../../../../../svg/Check';

export default function Step5() {
    const [preview, setPreview] = useState(null);
    const [file, setFile] = useState(null);
    const [headerTitle, setHeaderTitle] = useState('');
    const [headerSubtitle, setHeaderSubtitle] = useState('');
    const [headerLink, setHeaderLink] = useState('');
    
    let product = useSelector(state => state.focuspage.product);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMultipleFlow = product?.type === 'multiple';

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setHeaderLink(value);
    };

    useEffect(() => {
        if (product?.avatarPreview) {
            setPreview(product?.avatarPreview);
        }
        if (product?.headerTitle) setHeaderTitle(product.headerTitle);
        if (product?.headerSubtitle) setHeaderSubtitle(product.headerSubtitle);
        if (product?.headerLink) setHeaderLink(product.headerLink);
    }, [product]);

    const changeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreview(URL.createObjectURL(selectedFile));
            dispatch(setFocusProduct({ 
                avatar: selectedFile,
                avatarPreview: URL.createObjectURL(selectedFile)
            }));
        }
    };

    const handleNext = async () => {
        if (isMultipleFlow && (!headerTitle || !headerSubtitle || !isValidUrl(headerLink))) {
            dispatch(setErrorAlert('Please fill in all header fields with valid data'));
            return;
        }

        if (file) {
            dispatch(setLoader(true));
            try {
                const url = await uploadImage(file);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
                
                const productData = { 
                    avatar: url,
                    headeractive: true
                };

                if (isMultipleFlow) {
                    productData.headerTitle = headerTitle;
                    productData.headerSubtitle = headerSubtitle;
                    productData.headerLink = headerLink;
                }
                
                dispatch(setFocusProduct(productData));
                
                navigate(isMultipleFlow ? "/focus-page/product-type/step7" : "/focus-page/product-type/step6");
            } catch (error) {
                console.error('Error uploading image:', error);
                dispatch(setErrorAlert('Error uploading image'));
            } finally {
                dispatch(setLoader(false));
            }
        } else {
            navigate(isMultipleFlow ? "/focus-page/product-type/step7" : "/focus-page/product-type/step6");
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Add header image</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${file || product?.avatar ? "opacity-100" : "opacity-50"}`}
                                onClick={handleNext}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/product-type/step4")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4">
                        <div
                            className="text-white cursor-pointer"
                            onClick={() => document.getElementById("avatar").click()}
                        >
                            {preview ? "Change header image" : "Upload header image"}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            id="avatar"
                            onChange={changeAvatar}
                        />
                        {preview ? (
                            <div className="profile-picture my-8 min-h-[100px]">
                                <div className="avatar">
                                    <img
                                        src={preview}
                                        className="h-[80px] w-[80px] rounded-full"
                                        alt="header"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="mb-8" />
                        )}

                        {/* Additional fields for multiple flow */}
                        {isMultipleFlow && (
                            <>
                                <div className="mt-6">
                                    <DizeeInput
                                        placeholder="Add header title"
                                        onChange={(e) => setHeaderTitle(e.target.value)}
                                        value={headerTitle}
                                        className="w-full"
                                    />
                                </div>

                                <div className="mt-6">
                                    <DizeeInput
                                        placeholder="Add header subtitle"
                                        onChange={(e) => setHeaderSubtitle(e.target.value)}
                                        value={headerSubtitle}
                                        className="w-full"
                                    />
                                </div>

                                <div className="mt-6">
                                    <div className="flex items-center">
                                        <DizeeInput
                                            placeholder="Add header link"
                                            onChange={handleLinkChange}
                                            value={headerLink}
                                            className="w-full"
                                        />
                                        {isValidUrl(headerLink) && <Check className="h-[12px] w-[18px] ml-2" />}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Upload an image for your focus page header.</p>
                            {isMultipleFlow && (
                                <p>Add title, subtitle, and a valid link for your header section.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}

