import React from 'react';
import More from '../../svg/More';

export default function AddedTextCard({ text, setOverlayVisible, setItemForBackend }) {
    return (
        <div className='w-[350px] sm:w-[390px] p-4 flex flex-row justify-between items-center border border-[rgba(255,255,255,0.1)] rounded-[3px]'>
            <div className='flex flex-col justify-center'>
                <p className='text-white text-[12px] mb-2'>{text.title}</p>
                <p className='text-[rgba(255,255,255,0.5)] text-[10px]'>
                    {text.mainText.substring(0, 50)}
                    {text.mainText.length > 50 && '...'}
                </p>
                {text.optionalText && (
                    <p className='text-[rgba(255,255,255,0.3)] text-[10px] mt-1'>
                        {text.optionalText.substring(0, 30)}
                        {text.optionalText.length > 30 && '...'}
                    </p>
                )}
            </div>
            <More
                className='h-[20px] w-[20px] cursor-pointer text-white'
                onClick={() => {
                    setItemForBackend(text);
                    setOverlayVisible(true);
                }}
            />
        </div>
    );
} 