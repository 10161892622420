import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postDataAPI, getDataAPI } from '../../utils/fetchData';
import { setLoader, setErrorAlert } from '../alert/alertSlice';

// Thunks
export const addCustomToSectionThunk = createAsyncThunk(
  'customSection/addCustomToSection',
  async (tempData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      const response = await postDataAPI('custom-section/add', tempData.payload, tempData.token);
      thunkAPI.dispatch(setLoader(false));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCustomSectionsThunk = createAsyncThunk(
  'customSection/getCustomSections',
  async (tempData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      const response = await getDataAPI('custom-section/list', tempData.token);
      thunkAPI.dispatch(setLoader(false));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  sections: [],
  selectedTemplate: null,
  sectionTitle: '',
  loading: false,
  error: null,
  queueEntries: []
};

const customSectionSlice = createSlice({
  name: 'customSection',
  initialState,
  reducers: {
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    setSectionTitle: (state, action) => {
      state.sectionTitle = action.payload;
    },
    setQueueEntries: (state, action) => {
      state.queueEntries = action.payload;
    },
    addToQueue: (state, action) => {
      state.queueEntries.push(action.payload);
    },
    removeFromQueue: (state, action) => {
      state.queueEntries = state.queueEntries.filter((_, i) => i !== action.payload);
    },
    clearQueue: (state) => {
      state.queueEntries = [];
    },
    clearCustomSection: (state) => {
      state.selectedTemplate = null;
      state.sectionTitle = '';
    }
  }
});

export const { setSelectedTemplate, setSectionTitle, setQueueEntries, addToQueue, removeFromQueue, clearQueue, clearCustomSection } = customSectionSlice.actions;
export default customSectionSlice.reducer; 