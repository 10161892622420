import React, { useState, useEffect } from 'react';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import searchImg from '../../../../../assets/images/components/search.png';
import { DizeeInput2 } from '../../../../../components/DizeeComponent/DixeeInput2';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlerts, setErrorAlert, setLoader } from '../../../../../store/alert/alertSlice';
import { ClipLoader } from 'react-spinners';
import FocusAddedMusicCard from '../../../../../components/Focuspage/Type/Music/FocusAddedMusicCard';
import FocusMusicCard from '../../../../../components/Focuspage/Type/Music/FocusMusicCard';
import FocusMusicOverlay from '../../../../../components/Focuspage/Type/Music/FocusMusicOverlay';
import { clearFocusSection, clearSearchMusic } from '../../../../../store/focuspage/focuspageSlice';
import { addMusicForFocusThunk, searchMusicForReleaseFocusThunk } from '../../../../../store/focuspage/focuspageThunk';
import { uploadImage } from '../../../../../utils/upload';

export default function Step7() {
    const token = localStorage.getItem('dizeeToken');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [avtarForBackend, setAvatarForBackend] = useState('');

    const musicLoading = useSelector((state) => state.focuspage.musicLoading);
    const searchMusic = useSelector((state) => state.focuspage.searchMusic);
    const music = useSelector((state) => state.focuspage.music?.music);
    const musicData = useSelector((state) => state.focuspage.music);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        if (debouncedSearch.length < 6) {
            dispatch(clearSearchMusic());
            return;
        }
        dispatch(clearAlerts());
        dispatch(searchMusicForReleaseFocusThunk({ token, search: debouncedSearch }));
    }, [debouncedSearch, dispatch, token]);

    const handleAddMusicForFocus = async () => {
        if (!music?.length) {
            dispatch(setErrorAlert("Please add data to the section"));
            return;
        }

        dispatch(setLoader(true));

        try {
            let url = '';
            if (musicData.avatar) {
                url = await uploadImage(musicData.avatar);
                if (!url) {
                    dispatch(setErrorAlert('Image cannot contain nudity, violence or drugs'));
                    return;
                }
            }

            const musicDataCopy = { ...musicData };
            delete musicDataCopy.avatarPreview;

            const payload = {
                ...musicDataCopy,
                type: 'multiple',
                avatar: url || musicData.avatar,
                headeractive: true,
                headerTitle: musicData.headerTitle || '',
                headerSubtitle: musicData.headerSubtitle || ''
            };

            const res = await dispatch(addMusicForFocusThunk({ token, payload }));
            if (res.payload.status === 200) {
                dispatch(clearFocusSection());
                navigate("/focus-page");
            }
        } catch (error) {
            console.error('Error uploading image or adding music:', error);
        } finally {
            dispatch(setLoader(false));
        }
    };

    return (
        <LayoutHeader>
            <div className="w-[390px] h-[90vh] bg-black flex flex-col items-center relative">
                <div className='px-4 my-[50px] flex w-full justify-between'>
                    <p className='text-white' style={{ fontSize: '12px' }}>Search for a release</p>
                    <div className='flex justify-center items-center gap-4'>
                        <p className='text-white cursor-pointer' onClick={handleAddMusicForFocus} style={{ fontSize: '12px' }}>Done</p>
                        <Link to="/focus-page/music-type/step5">
                            <p className='text-white cursor-pointer' style={{ fontSize: '12px' }}>Go back</p>
                        </Link>
                    </div>
                </div>

                <div className='flex w-full flex-col gap-y-[50px]'>
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                            <div className='items-center flex flex-row text-white w-full'>
                                <DizeeInput2
                                    placeholder="Search by song, album, URL, UPC or ISRC"
                                    className="dizee-input w-full"
                                    search={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <img src={searchImg} alt='sp' className='h-[12px] w-[12px] mx-1' />
                        </div>
                        {musicLoading ? (
                            <ClipLoader
                                color="white"
                                loading={true}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : (
                            searchMusic?.length > 0 &&
                            searchMusic?.map((item, index) => (
                                <FocusMusicCard
                                    key={index}
                                    item={item}
                                />
                            ))
                        )}
                    </div>
                    {music?.length > 0 && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <div className='p-4 pb-[40px] flex w-full justify-between items-center cursor-pointer' style={{ fontSize: '12px' }}>
                                <div className='items-center flex flex-row text-white w-full'>
                                    <p>Added Music</p>
                                </div>
                            </div>
                            {music.map((item, index) => (
                                <FocusAddedMusicCard
                                    key={index}
                                    music={item}
                                    setAvatarForBackend={setAvatarForBackend}
                                    setOverlayVisible={setOverlayVisible}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {isOverlayVisible && (
                <FocusMusicOverlay 
                    isOverlayVisible={isOverlayVisible} 
                    setOverlayVisible={setOverlayVisible} 
                    avtarForBackend={avtarForBackend} 
                />
            )}
        </LayoutHeader>
    );
}
