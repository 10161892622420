import React from 'react';
import More from '../../svg/More';

export default function AddedSocialCard({ social, setOverlayVisible, setItemForBackend }) {
    return (
        <div className='w-full flex flex-row justify-between items-center p-0'>
            <div className='flex flex-row gap-x-4'>
                {social.preview && (
                    <div className='w-[50px] h-[50px] rounded-[3px] overflow-hidden'>
                        <img src={social.preview} alt="preview" className='w-full h-full object-cover' />
                    </div>
                )}
                <div className='flex flex-col justify-center'>
                    <p className='text-white text-[12px]'>{social.title}</p>
                </div>
            </div>
            <More
                className='h-[20px] w-[20px] cursor-pointer text-white'
                onClick={() => {
                    setItemForBackend(social);
                    setOverlayVisible(true);
                }}
            />
        </div>
    );
} 