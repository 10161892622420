import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import LayoutHeader from '../../../../layout/LayoutHeader';
import { DizeeInput2 } from '../../../../components/DizeeComponent/DixeeInput2';
import { setErrorAlert } from '../../../../store/alert/alertSlice';

const Container = styled.div`
  width: 390px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 27px;
  width: 350px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  margin-left: -18px;
`;

const FooterText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
`;

export default function CreateNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sectionTitle, setSectionTitle] = useState('');

  const handleNext = () => {
    if (!sectionTitle) {
      dispatch(setErrorAlert('Please enter a section title'));
      return;
    }
    navigate('/add-section/custom/select-style', {
      state: { sectionTitle }
    });
  };

  return (
    <LayoutHeader>
      <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
        <div className='px-4 my-[50px] flex w-full justify-between'>
          <p className='text-white'>Create New Section</p>
          <div className='flex gap-[30px]'>
            <button 
              onClick={handleNext}
              className='text-white cursor-pointer'>
              Next
            </button>
            <p onClick={() => navigate('/add-section/custom')} 
               className='text-white cursor-pointer'>
              Go back
            </p>
          </div>
        </div>

        <div className='w-full px-4'>
          <DizeeInput2
            label="Section Title"
            placeholder="Enter section title"
            value={sectionTitle}
            onChange={(e) => setSectionTitle(e.target.value)}
            className="dizee-input w-full text-white"
          />
        </div>

        <Footer>
          <FooterText>
            Create a custom section to showcase your content in a unique way. 
            Give your section a title that describes what you'll be sharing.
          </FooterText>
        </Footer>
      </div>
    </LayoutHeader>
  );
} 