import { useNavigate } from "react-router";
import Check from "../../../../../svg/Check";
import DizeeInput from "../../../../../components/DizeeComponent/DizeeInput";
import { useDispatch, useSelector } from "react-redux";
import LayoutHeader from "../../../../../layout/LayoutHeader";
import { setFocusProduct } from "../../../../../store/focuspage/focuspageSlice";
import { useState } from "react";
import { checkExtensionAvailableForProductThunk } from "../../../../../store/focuspage/focuspageThunk";

export default function Step1() {
    let user = localStorage.getItem("dizeeUser")
    let token = localStorage.getItem("dizeeToken")
    user = JSON.parse(user)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [extension, setExtension] = useState(true);
    let product = useSelector(state => state.focuspage.product)

    const handleChange = (e) => {
        const value = e.target.value;
        
        dispatch(setFocusProduct({ extension: value }));
        
        if (!value) {
            setExtension(false);
            return;
        }
        
        dispatch(checkExtensionAvailableForProductThunk({ extension: value, token }))
            .then((res) => {
                if (res.type.includes('fulfilled')) {
                    setExtension(true);
                } else {
                    setExtension(false);
                }
            })
            .catch((error) => {
                console.error('Extension check error:', error);
                setExtension(false);
            });
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-[80vh] flex flex-col items-center relative z-[0]">
                    <div className="px-4 my-[50px] flex w-full justify-between">
                        <p className="text-white">Add your extension</p>
                        <div className="flex gap-[30px]">
                            <div
                                className={`text-white cursor-pointer ${product?.extension !== undefined && product?.extension !== "" && extension
                                    ? "opacity-100"
                                    : "opacity-50"
                                }`}
                                onClick={() => (product?.extension !== undefined && product?.extension !== "") && extension && navigate("/focus-page/product-type/step2")}
                            >
                                Next
                            </div>
                            <div 
                                className="text-white cursor-pointer"
                                onClick={() => navigate("/focus-page/product-type")}
                            >
                                Go back
                            </div>
                        </div>
                    </div>

                    <div className="w-full px-4 flex items-center">
                        <span className="text-white">diz.ee/{user?.domain}/</span>
                        <DizeeInput
                            placeholder="product"
                            onChange={handleChange}
                            value={product?.extension}
                            className="w-[100px]"
                        />
                        {product?.extension !== undefined && product?.extension !== "" && extension && 
                            <Check className="h-[12px] w-[18px] ml-2" />
                        }
                    </div>

                    <div className="w-full px-4 absolute bottom-[27px] left-0">
                        <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5] max-w-full">
                            <p>Choose a unique extension for your focus page URL.</p>
                            <p>This will be part of your page's web address that you share with others.</p>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
}

