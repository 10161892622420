import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom } from '../../../../../store/focuspage/focuspageSlice';
import Check from '../../../../../svg/Check';
import QueuedEventCard from '../../../../../components/Cards/QueuedEventCard';
import AddedEventCard from '../../../../../components/Cards/AddedEventCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';

export default function AddEventContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const [title, setTitle] = useState('');
    const [lineOne, setLineOne] = useState('');
    const [lineTwo, setLineTwo] = useState('');
    const [lineThree, setLineThree] = useState('');
    const [link, setLink] = useState('');
    const [entries, setEntries] = useState([]);
    const [addedItems, setAddedItems] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);
    
    const isFormComplete = title && lineOne && lineTwo && lineThree && isValidUrl(link);

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const handleAddToQueue = () => {
        if (isFormComplete) {
            const newEntry = {
                title,
                lineOne,
                lineTwo,
                lineThree,
                link
            };
            setEntries([...entries, newEntry]);
            // Clear form
            setTitle('');
            setLineOne('');
            setLineTwo('');
            setLineThree('');
            setLink('');
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
    };

    const handleSubmit = () => {
        let contentToSubmit = [...entries];
        
        if (isFormComplete) {
            contentToSubmit.push({
                title,
                lineOne,
                lineTwo,
                lineThree,
                link
            });
        }

        if (contentToSubmit.length > 0) {
            setAddedItems(prev => [...prev, ...contentToSubmit]);
            setEntries([]);
            // Clear form
            setTitle('');
            setLineOne('');
            setLineTwo('');
            setLineThree('');
            setLink('');
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-screen flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-auto">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20 after:content-[''] after:absolute after:left-0 after:right-0 after:top-[-50px] after:h-[50px] after:bg-black">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddToQueue}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${(entries.length > 0 || isFormComplete) ? "opacity-100" : "opacity-50"}`}
                                    onClick={() => (entries.length > 0 || isFormComplete) && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div 
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-4 mt-[120px] relative z-10 mb-[100px]">
                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    maxLength={30}
                                />
                                {title && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter first line"
                                    onChange={(e) => setLineOne(e.target.value)}
                                    value={lineOne}
                                    maxLength={30}
                                />
                                {lineOne && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter second line"
                                    onChange={(e) => setLineTwo(e.target.value)}
                                    value={lineTwo}
                                    maxLength={30}
                                />
                                {lineTwo && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter third line"
                                    onChange={(e) => setLineThree(e.target.value)}
                                    value={lineThree}
                                    maxLength={30}
                                />
                                {lineThree && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center mb-4">
                                <DizeeInput
                                    placeholder="Enter link"
                                    onChange={handleLinkChange}
                                    value={link}
                                />
                                {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            {entries.length > 0 && (
                                <div className="w-full mt-8">
                                    <p className="text-white text-[12px] mb-4">In Queue</p>
                                    <div className="flex flex-col gap-y-4 w-full">
                                        {entries.map((entry, index) => (
                                            <QueuedEventCard
                                                key={index}
                                                event={entry}
                                                onDelete={() => handleDeleteFromQueue(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {addedItems.length > 0 && (
                                <div className="flex flex-col justify-center items-center w-full mt-8">
                                    <div className="p-4 pb-[40px] flex w-full justify-between items-center">
                                        <div className="items-center flex flex-row text-white w-full">
                                            <p>Added Events</p>
                                        </div>
                                    </div>
                                    {addedItems.map((event, index) => (
                                        <AddedEventCard
                                            key={index}
                                            event={event}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={setItemForBackend}
                                        />
                                    ))}
                                </div>
                            )}

                            {isOverlayVisible && (
                                <CustomOverlay 
                                    setOverlayVisible={setIsOverlayVisible}
                                    itemForBackend={itemForBackend}
                                    setItemForBackend={setItemForBackend}
                                />
                            )}
                        </div>

                        <div className="w-full fixed bottom-0 left-0 bg-black py-4 z-10">
                            <div className="w-[390px] mx-auto px-4">
                                <div className="text-[12px] text-[rgba(255,255,255,0.5)] leading-[1.5]">
                                    <p>Add your event content to display in your focus page.</p>
                                    <p>All fields are required to create an event listing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutHeader>
    );
} 