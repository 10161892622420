import React from 'react';
import Plus from '../../svg/Plus';

export default function QueuedTextCard({ text, onDelete }) {
    return (
        <div className='w-full flex flex-row justify-between items-center p-4 border border-[rgba(255,255,255,0.1)] rounded-[3px]'>
            <div className='flex flex-col justify-center'>
                <p className='text-white text-[12px] mb-2'>{text.title}</p>
                <p className='text-[rgba(255,255,255,0.5)] text-[10px]'>
                    {text.mainText.substring(0, 50)}
                    {text.mainText.length > 50 && '...'}
                </p>
            </div>
            <button onClick={onDelete}>
                <Plus className='h-[12px] w-[12px] rotate-45 cursor-pointer text-white' />
            </button>
        </div>
    );
} 