import React from 'react';
import { useDispatch } from 'react-redux';

export default function AddedCustomCard({ item, template }) {
  const dispatch = useDispatch();

  return (
    <div className="w-full px-4 mb-4">
      <div className="bg-[#1F1F1F] rounded-lg p-4">
        <div className="flex items-start">
          <div className="flex-1">
            {item.image && (
              <img 
                src={item.image} 
                alt={item.title} 
                className="w-16 h-16 object-cover rounded-lg mb-2"
              />
            )}
            <h3 className="text-white text-sm font-medium">{item.title}</h3>
            {item.description && (
              <p className="text-gray-400 text-xs mt-1">{item.description}</p>
            )}
            {item.link && (
              <p className="text-gray-400 text-xs mt-1">{item.link}</p>
            )}
            {item.date && (
              <p className="text-gray-400 text-xs mt-1">{item.date}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 