import React from 'react';
import More from '../../svg/More';

export default function AddedEventCard({ event, setOverlayVisible, setItemForBackend }) {
    return (
        <div className='w-full flex flex-row justify-between items-center p-0'>
            <div className='flex flex-row gap-x-4'>
                <div className='flex flex-col justify-center'>
                    <p className='text-white text-[12px]'>{event.title}</p>
                    <p className='text-[rgba(255,255,255,0.5)] text-[12px]'>{event.link}</p>
                </div>
            </div>
            <More
                className='h-[20px] w-[20px] cursor-pointer text-white'
                onClick={() => {
                    setItemForBackend(event);
                    setOverlayVisible(true);
                }}
            />
        </div>
    );
} 