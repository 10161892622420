import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LayoutHeader from '../../../../../layout/LayoutHeader';
import DizeeInput from '../../../../../components/DizeeComponent/DizeeInput';
import { setFocusCustom } from '../../../../../store/focuspage/focuspageSlice';
import Check from '../../../../../svg/Check';
import QueuedProductCard from '../../../../../components/Cards/QueuedProductCard';
import AddedProductCard from '../../../../../components/Cards/AddedProductCard';
import CustomOverlay from '../../../../../components/Focuspage/Type/Custom/CustomOverlay';

export default function AddProductContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const style = state?.style || 'product';

    useEffect(() => {
        if (style !== 'product') {
            navigate(-1);
        }
    }, [style, navigate]);

    const isValidUrl = (url) => {
        if (!url) return false;
        const tldPattern = /\.[a-z]{1,}$/i;
        try {
            const urlObj = new URL(url);
            return tldPattern.test(urlObj.hostname);
        } catch {
            return false;
        }
    };

    const [title, setTitle] = useState('');
    const [header, setHeader] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [entries, setEntries] = useState([]);
    const [addedProducts, setAddedProducts] = useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [itemForBackend, setItemForBackend] = useState(null);
    
    const isFormComplete = title && header && description && isValidUrl(link) && preview;

    const handleLinkChange = (e) => {
        let value = e.target.value;
        if (value && !value.startsWith('http')) {
            value = `https://${value}`;
        }
        setLink(value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        e.target.value = '';
    };

    const handleAddToQueue = () => {
        if (isFormComplete) {
            const newEntry = {
                title,
                header,
                description,
                link,
                preview
            };
            setEntries([...entries, newEntry]);
            // Clear form
            setTitle('');
            setHeader('');
            setDescription('');
            setLink('');
            setPreview(null);
            setFile(null);
        }
    };

    const handleDeleteFromQueue = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
    };

    const handleSubmit = () => {
        let contentToSubmit = [...entries];
        
        if (isFormComplete) {
            contentToSubmit.push({
                title,
                header,
                description,
                link,
                preview
            });
        }

        if (contentToSubmit.length > 0) {
            setAddedProducts(prev => [...prev, ...contentToSubmit]);
            setEntries([]);
            // Clear form
            setTitle('');
            setHeader('');
            setDescription('');
            setLink('');
            setPreview(null);
            setFile(null);
        }
    };

    return (
        <LayoutHeader>
            <div className="flex justify-center w-full">
                <div className="w-[390px] h-screen flex flex-col items-center relative">
                    <div className="w-full flex flex-col flex-1 overflow-auto">
                        <div className="px-4 my-[50px] flex w-full justify-between fixed w-full sm:w-[390px] bg-black z-20">
                            <p className="text-white">Add content</p>
                            <div className="flex gap-[30px]">
                                {isFormComplete && (
                                    <div
                                        className="text-white cursor-pointer"
                                        onClick={handleAddToQueue}
                                    >
                                        Another
                                    </div>
                                )}
                                <div
                                    className={`text-white cursor-pointer ${(entries.length > 0 || isFormComplete) ? "opacity-100" : "opacity-50"}`}
                                    onClick={() => (entries.length > 0 || isFormComplete) && handleSubmit()}
                                >
                                    Done
                                </div>
                                <div 
                                    className="text-white cursor-pointer"
                                    onClick={() => navigate(-1)}
                                >
                                    Go back
                                </div>
                            </div>
                        </div>

                        <div className="mt-[120px] px-4 flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    maxLength={30}
                                />
                                {title && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter header"
                                    onChange={(e) => setHeader(e.target.value)}
                                    value={header}
                                    maxLength={30}
                                />
                                {header && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={description}
                                    maxLength={100}
                                />
                                {description && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="flex items-center">
                                <DizeeInput
                                    placeholder="Enter link"
                                    onChange={handleLinkChange}
                                    value={link}
                                />
                                {isValidUrl(link) && <Check className="h-[12px] w-[18px] ml-2" />}
                            </div>

                            <div className="w-full">
                                <p 
                                    className="text-white text-[12px] mb-2 cursor-pointer"
                                    onClick={() => document.getElementById('file-input').click()}
                                >
                                    {preview ? 'Change image' : 'Upload image'}
                                </p>
                                {preview && (
                                    <div className="w-[50px] h-[50px] rounded-[3px] overflow-hidden">
                                        <img src={preview} alt="preview" className="w-full h-full object-cover" />
                                    </div>
                                )}
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    className="hidden"
                                    accept="image/*"
                                />
                            </div>

                            {entries.length > 0 && (
                                <div className="w-full mt-8">
                                    <p className="text-white text-[12px] mb-4">In Queue</p>
                                    <div className="flex flex-col gap-y-4 w-full">
                                        {entries.map((entry, index) => (
                                            <QueuedProductCard
                                                key={index}
                                                product={entry}
                                                onDelete={() => handleDeleteFromQueue(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {addedProducts.length > 0 && (
                                <div className="flex flex-col justify-center items-center w-full mt-8">
                                    <div className="p-4 pb-[40px] flex w-full justify-between items-center">
                                        <div className="items-center flex flex-row text-white w-full">
                                            <p>Added Products</p>
                                        </div>
                                    </div>
                                    {addedProducts.map((product, index) => (
                                        <AddedProductCard
                                            key={index}
                                            product={product}
                                            setOverlayVisible={setIsOverlayVisible}
                                            setItemForBackend={setItemForBackend}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isOverlayVisible && (
                <CustomOverlay 
                    setOverlayVisible={setIsOverlayVisible}
                    itemForBackend={itemForBackend}
                    setItemForBackend={setItemForBackend}
                    contentType="product"
                />
            )}
        </LayoutHeader>
    );
} 